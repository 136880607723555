<template>
  <h1><span>Logout Page</span></h1>
</template>
<script>
export default {
  data () {
    return {};
  },
  beforeCreate () {
    const _url = '/login';
    localStorage.removeItem(process.env.VUE_APP_USER);

    this.$store.dispatch('resetState');
    this.$router.push(_url).catch((error) => {
      console.log('logout catch error');
      console.log(error);
      // console.log('[logout][beforCreate] ... end ');
    });
  }
};
</script>
<style scoped>
h1 {
  color: green;
}
</style>
